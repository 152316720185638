import { PageViewProperties } from '../types/PageViewProperties'
import SegmentWrapper from '../utils/metrics/segmentWindow'
import { useEffect } from 'react'

const useTrackVirtualPageView = (page?: string, properties?: PageViewProperties | null, shouldTrack = true) => {
  useEffect(() => {
    if (shouldTrack && page) {
      SegmentWrapper.virtualPageView(page, properties)
    }
  }, [shouldTrack, page, properties])
}

export default useTrackVirtualPageView
