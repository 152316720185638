import { useEffect } from 'react'
import SegmentWrapper from '../utils/metrics/segmentWindow'
import { PaymentJourneyInfo } from '../types/PaymentJourneyInfo'
import { useCookies } from 'react-cookie'
import { cookieNames } from '../utils/cookieUtils'

const useIdentify = (data: PaymentJourneyInfo | undefined) => {
  const [cookies] = useCookies([cookieNames.analyticsUid, cookieNames.isEmployee])

  useEffect(() => {
    if (data === undefined) return

    const isEmployee: boolean = (cookies[cookieNames.isEmployee] as string) !== undefined
    const userId: string | null = (cookies[cookieNames.analyticsUid] as string) || null

    SegmentWrapper.identify(userId, {
      isEmployee: isEmployee,
      merchant: data.marketplace?.name || 'Unknown',
      buyerId: data.orderDetails?.company?.kriyaCompanyIdentifier,
      email: data.orderDetails?.user?.email,
      name: data.orderDetails?.user?.fullName
    })
  }, [data])
}

export default useIdentify
