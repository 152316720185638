import React, { useEffect } from 'react'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import OrderErrors, { ErrorMessage } from '../error/OrderErrors'
import { MfaRecipient } from '../../types/MfaRecipient'
import { useMfaSession } from '../../custom-hooks/useMfaSession'
import { SessionStatus } from '../../types/enums/SessionStatus'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const MfaRejected = () => {
  useVirtualPageView(VirtualPage.MFA_REJECTED)

  const navigate = useNavigate()
  const paymentJourneyData = useFetchCacheData()

  const mfaParams =
    paymentJourneyData && paymentJourneyData.session?.token
      ? {
          marketplaceOrderId: paymentJourneyData.orderDetails.marketPlaceOrderId,
          sessionToken: paymentJourneyData.session.token
        }
      : null

  const { data: mfaSession, isLoading: isMfaLoading, error: mfaError } = useMfaSession(mfaParams)

  const redirectToError = () => {
    navigate('/error', {
      replace: true
    })
  }

  useEffect(() => {
    if (paymentJourneyData === undefined || paymentJourneyData.session?.token === null) {
      redirectToError()
    }
  }, [paymentJourneyData])

  useEffect(() => {
    if (
      mfaError ||
      (!isMfaLoading &&
        mfaSession?.recipients.find((recipient: MfaRecipient) => recipient.state === SessionStatus.Rejected) ===
          undefined)
    ) {
      redirectToError()
    }
  }, [mfaError, isMfaLoading, mfaSession])

  if (isMfaLoading) {
    return null
  }

  if (!paymentJourneyData || !mfaSession) {
    return null
  }

  const rejectedBy = mfaSession.recipients.find((recipient: MfaRecipient) => recipient.state === SessionStatus.Rejected)

  if (!rejectedBy) {
    redirectToError()
    return null
  }

  const errorHeader = `Order rejected`

  return (
    <OrderErrors
      errorHeader={errorHeader}
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Unfortunately, your order was rejected by {rejectedBy.name} (mobile {rejectedBy.destination}).
      </ErrorMessage>
      <ErrorMessage>Please contact them for further assistance.</ErrorMessage>
    </OrderErrors>
  )
}

export default MfaRejected
