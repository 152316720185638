import { Navigate, useParams } from 'react-router-dom'
import { StyledLoadingButton } from '../../components/common/button/Buttons'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Loading from '../../components/common/loading/Loading'
import { formatNumber } from '../../utils/amountFormatter'
import { formatDateTime } from '../../utils/dateTimeFormatter'
import { StyledButtonsContainer } from '../../components/layout/Container'
import { useUpdateMfaDecision } from '../../custom-hooks/useUpdateMfaDecision'
import Stack from '@mui/material/Stack'
import theme from '../../assets/theme'
import { IsMfaSessionCompleted, IsMfaSessionInProgress, MfaSessionState } from '../../types/enums/MfaSessionState'
import { useMfaSession } from '../../custom-hooks/useMfaSession'
import { SessionStatus } from '../../types/enums/SessionStatus'
import { BusinessType } from '../../types/enums/BusinessType'
import { MfaSession } from '../../types/MfaSession'
import { RecipientType } from '../../types/enums/RecipientType'
import Logo from '../../components/common/logo/Logo'
import { useResendMfaSession } from '../../custom-hooks/useResendMfaSession'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Link from '@mui/material/Link'
import UnrecognizedOrderDialog from './UnrecognizedOrderDialog'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const StyledBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(0, 40, 60, 0.5);
  background: rgba(255, 245, 227, 0.34);
  box-shadow: 8px 8px 0 0 #ebeeef;

  ${theme.breakpoints.down('mobile')} {
    padding: 30px 38px;
  }

  padding: 48px 64px;
`

const ResponsiveStack = styled(Stack)`
  ${theme.breakpoints.down('mobile')} {
    margin-bottom: ${theme.spacing(7)};
  }

  margin-bottom: ${theme.spacing(11)};
`

const StyledDecision = styled.span`
  font-weight: 700;
`

const getHeaderContent = (data: MfaSession) => {
  if (data.recipientType === RecipientType.Admin && data.orderSnapshot.companyType === BusinessType.LimitedCompany) {
    return (
      <>
        <Typography variant="body2">
          As part of the Kriya order verification process, a purchase made by {data.orderSnapshot.companyName} is
          currently pending at{` `}
          {data.orderSnapshot.merchantName}.
        </Typography>
        <Typography variant="body2">
          Please note: the individual placing this order has not completed a previous purchase. Your approval is
          required to proceed. if you recognise this order, please confirm your approval, by clicking the "Confirm
          order" button below.
        </Typography>
        <Typography variant="body2">
          Once approved, this individual will be authorised to confirm future orders independently.
        </Typography>
        <Typography variant="body2">
          If you do not recognise this order, please reject it or take no action. Need help? Contact us at{' '}
          <Link href="mailto:customersupport@kriya.co" variant="body2">
            customersupport@kriya.co
          </Link>
          .
        </Typography>
      </>
    )
  }
  if (data.recipientType !== RecipientType.Unknown) {
    return (
      <>
        <Typography variant="body2">
          As part of the Kriya order verification process, a purchase made by {data.orderSnapshot.companyName} is
          currently pending at {data.orderSnapshot.merchantName}. As you have successfully completed a previous order at{' '}
          {data.orderSnapshot.merchantName}, you are authorised to approve this transaction on behalf of your
          organisation.
        </Typography>
        <Typography variant="body2">
          If you do not recognise this order, please reject it or take no action. Need help? Contact us at{' '}
          <Link href="mailto:customersupport@kriya.co" variant="body2">
            customersupport@kriya.co
          </Link>
          .
        </Typography>
      </>
    )
  }
}

const MfaApproval = () => {
  useVirtualPageView(VirtualPage.MFA_APPROVAL)
  const { merchantOrderId, sessionToken, recipientId } = useParams()
  const [resendTriggered, setResendTriggered] = useState(false)

  const { data } = useMfaSession({
    marketplaceOrderId: merchantOrderId!,
    sessionToken: sessionToken!,
    recipientId: recipientId
  })

  const { mutate: updateDecisionMutation, isLoading: submitDecisionLoading } = useUpdateMfaDecision(
    merchantOrderId!,
    sessionToken!,
    recipientId!
  )

  const { mutate: resendMfaSessionMutation, isLoading: resendMfaSessionLoading } = useResendMfaSession()

  const submitDecision = (decision: SessionStatus) => {
    updateDecisionMutation(decision)
  }
  const onResendSmsClicked = () => {
    resendMfaSessionMutation(
      {
        marketplaceOrderId: merchantOrderId!,
        sessionToken: sessionToken!
      },
      {
        onSuccess: () => {
          setResendTriggered(true)
        }
      }
    )
  }

  if (!data) {
    return <Loading />
  }

  if (data.recipientType === RecipientType.Unknown) {
    return <Navigate to={'/not-found'} replace />
  }

  let gridContent = [
    {
      title: 'Merchant',
      value: data.orderSnapshot.merchantName
    },
    {
      title: 'Amount',
      value: formatNumber(data.orderSnapshot.amount.amount, data.orderSnapshot.amount.currency)
    },
    {
      title: 'Payment method',
      value: data.orderSnapshot.paymentMethodName
    },
    ...(data.orderSnapshot.internalFee.amount > 0
      ? [
          {
            title: 'Kriya fee',
            value: formatNumber(data.orderSnapshot.internalFee.amount, data.orderSnapshot.internalFee.currency)
          }
        ]
      : []),
    {
      title: 'Order created on',
      value: formatDateTime(data.orderSnapshot.createdAt)
    },
    {
      title: 'Delivery address',
      value: data.orderSnapshot.deliveryAddress
    }
  ]
  if (data.orderSnapshot.companyType !== BusinessType.SoleTrader) {
    gridContent = [
      ...gridContent,
      {
        title: 'Buyer name',
        value: data.orderSnapshot.userName
      },
      {
        title: 'Buyer email',
        value: data.orderSnapshot.userEmail
      },
      {
        title: 'Buyer mobile number',
        value: data.orderSnapshot.userPhoneNumber
      }
    ]
  }

  const decisionMapper = (state: MfaSessionState) => {
    if (state === MfaSessionState.Rejected) return 'rejected'
    if (state === MfaSessionState.Approved) return 'confirmed'
    if (state === MfaSessionState.Expired) return 'no response yet'
    if (state === MfaSessionState.Cancelled) return 'cancelled'
    return ''
  }
  const getDecisivePersonBasedOnState = (state: MfaSessionState) => {
    if (!IsMfaSessionCompleted(state)) return ''

    const person = data.recipients.filter(
      (r) =>
        (state === MfaSessionState.Approved && r.state === SessionStatus.Approved) || r.state === SessionStatus.Rejected
    )
    if (person.length) {
      return `${person[0].name} (phone: ${person[0].destination})`
    }
    return ''
  }

  return (
    <>
      <Logo marketplace={{ name: data.orderSnapshot.merchantName, logoUri: data.orderSnapshot.logoUri }} />
      {IsMfaSessionInProgress(data.state) && (
        <Stack sx={{ gap: '28px', marginBottom: theme.spacing(8) }}>{getHeaderContent(data)}</Stack>
      )}
      <StyledBox
        sx={{
          marginBottom: theme.spacing(8)
        }}
      >
        {!IsMfaSessionInProgress(data.state) && (
          <ResponsiveStack>
            <Typography variant="h1" sx={{ marginBottom: theme.spacing(5) }}>
              Verification result: <StyledDecision>{decisionMapper(data.state)}</StyledDecision>
            </Typography>
            {data.state === MfaSessionState.Expired && (
              <>
                <Typography variant="errorBody">
                  We haven't received a response since the SMS was sent. Feel free to send the SMS again to restart the
                  verification process.
                </Typography>

                {!resendTriggered && (
                  <StyledButtonsContainer sx={{ marginTop: '44px !important' }}>
                    <StyledLoadingButton
                      type="submit"
                      loading={resendMfaSessionLoading}
                      disabled={resendMfaSessionLoading}
                      loadingPosition="end"
                      onClick={onResendSmsClicked}
                      id="resend-btn"
                      variant="contained"
                    >
                      Resend SMS
                    </StyledLoadingButton>
                  </StyledButtonsContainer>
                )}
                {resendTriggered && (
                  <Typography variant="body2" sx={{ color: '#198754', marginTop: theme.spacing(8) }}>
                    <CheckCircle sx={{ verticalAlign: 'middle' }} fontSize="small" /> Message sent
                  </Typography>
                )}
              </>
            )}
            {IsMfaSessionCompleted(data.state) && (
              <Typography>by {getDecisivePersonBasedOnState(data.state)}</Typography>
            )}
          </ResponsiveStack>
        )}
        <Grid container spacing={5} data-cy="order-details-box">
          <Grid item xs={12} sx={{ marginBottom: theme.spacing(5) }}>
            <Typography variant="h1">Order details</Typography>
          </Grid>
          {gridContent
            .filter((r) => (r.value?.length ?? 0) > 0)
            .map((r, index) => (
              <Grid item xs={12} md={6} key={`grid-content-${index}`}>
                <Stack>
                  <Typography variant="h2">{r.title}</Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.common.textGrey900 }}>
                    {r.value}
                  </Typography>
                </Stack>
              </Grid>
            ))}
        </Grid>
      </StyledBox>

      {IsMfaSessionInProgress(data.state) && (
        <>
          {data.orderSnapshot.companyType === BusinessType.SoleTrader && (
            <Typography variant="inputLabel">
              By clicking "Confirm order" you confirm that these order details are correct.
            </Typography>
          )}
          <StyledButtonsContainer
            sx={{
              marginTop:
                data.orderSnapshot.companyType === BusinessType.SoleTrader ? '32px !important' : theme.spacing(11),
              paddingBottom: theme.spacing(13)
            }}
          >
            <StyledLoadingButton
              type="submit"
              id="continue-button"
              loading={submitDecisionLoading}
              disabled={submitDecisionLoading}
              loadingPosition="end"
              onClick={() => submitDecision(SessionStatus.Approved)}
              variant="contained"
            >
              Confirm order
            </StyledLoadingButton>
            <UnrecognizedOrderDialog
              disabled={submitDecisionLoading}
              onConfirm={() => submitDecision(SessionStatus.Rejected)}
            />
          </StyledButtonsContainer>
        </>
      )}
    </>
  )
}

export default MfaApproval
