export enum VirtualPage {
  CUSTOMER_DETAILS = 'Checkout - Customer Details',
  PAYMENT = 'Checkout - Payment',
  RISK_REVIEW = 'Checkout - Risk Review',
  RISK_REJECTED = 'Checkout - Risk Rejected',
  MFA_APPROVAL = 'Checkout - MFA Approval',
  MFA_APPROVED = 'Checkout - MFA Approved',
  MFA_EXPIRED = 'Checkout - MFA Expired',
  MFA_REJECTED = 'Checkout - MFA Rejected',
  MFA_NOT_SUPPORTED = 'Checkout - MFA Not Supported',
  MFA_CONFIRMATION_POLLING = 'Checkout - MFA Confirmation Polling',
  CHECKS_FAILED = 'Checkout - Checks Failed',
  INVALID_ORDER_STATUS = 'Checkout - Invalid Order Status',
  INSUFFICIENT_FUNDS = 'Checkout - Insufficient Funds',
  ORDER_NOT_FOUND = 'Checkout - Order Not Found',
  UNEXPECTED_ERROR = 'Checkout - Unexpected Error',
  INVALID_LINK = 'Checkout - Invalid Link'
}
