import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/common/loading/Loading'
import CompanyStructure from './CompanyStructure'
import { BusinessType } from '../../types/enums/BusinessType'
import SoleTraderDetails from './SoleTraderDetails'
import { TerminalPaymentJourneyStatuses, PaymentJourneyStatus } from '../../types/enums/PaymentJourneyStatus'
import { PaymentJourneyInfo } from '../../types/PaymentJourneyInfo'
import CustomerDetailsWrapper from './CustomerDetailsWrapper'
import LimitedCompanyDetails from './LimitedCompanyDetails'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

const CustomerDetails: React.FC<PaymentJourneyInfo> = (data) => {
  useVirtualPageView(VirtualPage.CUSTOMER_DETAILS)
  const trackEvent = useTrackEvent()
  const { shortCode } = useParams()
  const orderInfo = data.orderDetails
  const selectBusinessTypeOptionsAvailable =
    data.paymentJourney.shouldCollectCompanyDetails && data.marketplace?.supportedCompanies?.length > 1
  const navigate = useNavigate()
  const [companyType, setCompanyType] = useState<string | undefined>(data.orderDetails.company?.type)
  const isEngagementThresholdMet = useRef<boolean>(false)

  const isBusinessTypeSupported = (businessType: BusinessType) => {
    if (data.marketplace.supportedCompanies?.indexOf(businessType) >= 0) return true
    if (businessType == BusinessType.LimitedCompany && !data.marketplace.supportedCompanies?.length) return true
    return false
  }

  const onSaveUserMutationSuccess = (data: PaymentJourneyInfo) => {
    if (TerminalPaymentJourneyStatuses.includes(data.paymentJourney.status as PaymentJourneyStatus)) {
      return <Loading />
    }
    navigate(`/${shortCode as string}/payment`)
  }

  useEffect(() => {
    if (!isEngagementThresholdMet.current) {
      const timer = setTimeout(() => {
        isEngagementThresholdMet.current = true
        trackEvent(EventTypes.CUSTOMER_DETAILS.ENGAGEMENT_MET)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <CustomerDetailsWrapper data={data}>
        {selectBusinessTypeOptionsAvailable && (
          <CompanyStructure
            marketplaceName={data.marketplace.name}
            redirectUrl={data.paymentJourney.paymentCancelledRedirectUrl as string}
            companyType={companyType}
            onCompanyTypeChange={setCompanyType}
          />
        )}
        {((selectBusinessTypeOptionsAvailable && companyType === BusinessType.LimitedCompany.toString()) ||
          orderInfo.company?.type === BusinessType.LimitedCompany.toString() ||
          (!selectBusinessTypeOptionsAvailable &&
            orderInfo.company?.type === undefined &&
            isBusinessTypeSupported(BusinessType.LimitedCompany))) && (
          <LimitedCompanyDetails data={data} onSaveUserMutationSuccess={onSaveUserMutationSuccess} />
        )}
        {((selectBusinessTypeOptionsAvailable && companyType === BusinessType.SoleTrader.toString()) ||
          orderInfo.company?.type === BusinessType.SoleTrader.toString() ||
          (!selectBusinessTypeOptionsAvailable &&
            orderInfo.company?.type === undefined &&
            isBusinessTypeSupported(BusinessType.SoleTrader))) && (
          <SoleTraderDetails
            orderInfo={orderInfo}
            availableCountries={data.paymentJourney.availableCountries}
            onSaveuserMutationSuccess={onSaveUserMutationSuccess}
          />
        )}
      </CustomerDetailsWrapper>
    </>
  )
}

export default CustomerDetails
