import { PageViewProperties } from '../../types/PageViewProperties'
import { TrackEventProperties } from '../../types/TrackEventProperties'

interface ISegment {
  page: (input: string, props?: PageViewProperties) => void
  virtualPageView: (path: string, props?: PageViewProperties | null) => void
  track: (eventName: string, props?: TrackEventProperties | null) => void
  identify: (id: string | null | undefined, data: IIdentityData) => void
}

interface IIdentityData {
  merchant: string
  isEmployee: boolean
  buyerId?: string
  email?: string
  name?: string
}

interface ISegmentWindow {
  analytics: ISegment
  hj?: (arg1: string, arg2: string | string[]) => void
}

const isSegmentWindow = (win: any): win is ISegmentWindow => {
  return (win as { analytics?: any }).analytics !== undefined
}

const mockSegmentWindow: ISegmentWindow = {
  analytics: {
    page: (input: string, props?: PageViewProperties | null): void => {
      console.log(`Tracking page view: ${JSON.stringify(input)} - ${JSON.stringify(props)}`)
    },
    virtualPageView: (path: string, props?: PageViewProperties | null) => {
      console.log(`Tracking virtual page view: ${JSON.stringify(path)} - ${JSON.stringify(props)}`)
    },
    track: (eventName: string, props?: TrackEventProperties | null) => {
      console.log(`Tracking event: ${eventName}. Props: ${JSON.stringify(props || {})}`)
    },
    identify: (id: string | null | undefined, data: IIdentityData) => {
      console.log(`Identifying user. id: ${id || ''}. data: ${JSON.stringify(data)}`)
    }
  },
  hj: undefined
}

class SegmentWrapperImpl implements ISegment {
  private segmentWindow?: ISegmentWindow

  constructor() {
    this.segmentWindow = isSegmentWindow(window) ? window : mockSegmentWindow
  }

  page = (input: string, props?: PageViewProperties): void => {
    this.segmentWindow?.analytics.page(input, props || {})
  }

  virtualPageView = (path: string, props?: PageViewProperties | null): void => {
    this.segmentWindow?.analytics.page(path, props || {})

    if (typeof this.segmentWindow?.hj === 'function' && this.segmentWindow?.hj !== undefined) {
      this.segmentWindow?.hj('vpv', `/${path}/`)
      this.segmentWindow?.hj('trigger', path)
      this.segmentWindow?.hj('tagRecording', [`/${path}/`])
    }
  }

  track = (eventName: string, props?: TrackEventProperties | null): void => {
    if (this.segmentWindow?.analytics) {
      this.segmentWindow.analytics.track(eventName, props || {})
    } else {
      console.error('Segment analytics is not initialized.')
    }
  }

  identify = (id: string | null | undefined, data: IIdentityData): void => {
    if (this.segmentWindow?.analytics) {
      this.segmentWindow.analytics.identify(id, data)
    } else {
      console.error('Segment analytics is not initialized.')
    }
  }
}

const SegmentWrapper = new SegmentWrapperImpl()
export default SegmentWrapper
