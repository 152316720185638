import React from 'react'
import Typography from '@mui/material/Typography'
import SystemErrors from './SystemErrors'
import Link from '@mui/material/Link'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const InternalServerError = () => {
  useVirtualPageView(VirtualPage.UNEXPECTED_ERROR)

  return (
    <SystemErrors title="Oops... something went wrong">
      <Typography variant="errorBody">
        Sorry for the inconvenience. Please{' '}
        <Link href="mailto:customersupport@kriya.co" variant="errorBody">
          contact us
        </Link>{' '}
        if you need any support.
      </Typography>
    </SystemErrors>
  )
}

export default InternalServerError
