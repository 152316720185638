import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { UseFormReturn } from 'react-hook-form'
import { CustomerDetailsForm } from '../../types/CustomerDetailsForm'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import ErrorText from '../input/FormErrors'
import FormLabel from '@mui/material/FormLabel'
import useDebounce from '../../utils/useDebounce'
import { useFetchCompanyAutocompleteData } from '../../custom-hooks/useFetchCompanyAutocompleteData'
import FormControl from '@mui/material/FormControl'
import { SearchCompanyDetails } from '../../types/SearchCompanyDetails'
import { getGbCompanyRegistrationNumber, getSuitableCompanyIdentifier } from '../../utils/auxiliaryIdentifierProvider'
import { dnbCountryRegistrationNumberType } from '../../consts'
import { Address } from '../../types/Address'
import Grid from '@mui/material/Grid'

type SearchModeProps = {
  form: UseFormReturn<CustomerDetailsForm, any>
  toggleMode: () => void
}

export const SearchModeComponent = ({ form, toggleMode }: SearchModeProps) => {
  const {
    setValue,
    register,
    getValues,
    formState: { errors }
  } = form
  const [autocompleteValue, setAutocompleteValue] = useState<SearchCompanyDetails | null>({
    name: getValues('company.companyName') ?? '',
    kriyaCompanyIdentifier: getValues('company.kriyaCompanyIdentifier') ?? '',
    auxiliaryIdentifiers: getValues('company.kriyaCompanyIdentifier')
      ? [
          {
            identifier: getValues('company.kriyaCompanyIdentifier')!,
            type: dnbCountryRegistrationNumberType,
            isNationalId: true
          }
        ]
      : []
  })

  const [inputValue, setInputValue] = useState<string>('')
  register('company.companyName', { required: 'Enter name or organisation number' })

  const debouncedSearch = useDebounce(inputValue, 500)
  const { isLoading, data } = useFetchCompanyAutocompleteData(debouncedSearch, 2)

  return (
    <FormControl>
      <FormLabel
        htmlFor="company-search-autocomplete"
        sx={{
          typography: 'inputLabel'
        }}
      >
        Your organisation
      </FormLabel>

      <Autocomplete
        id="company-search-autocomplete"
        data-cy="company-search-autocomplete"
        value={autocompleteValue}
        filterOptions={(options) => options}
        isOptionEqualToValue={(option, value) => option.kriyaCompanyIdentifier === value.kriyaCompanyIdentifier}
        freeSolo={true}
        onChange={(_event, value, reason) => {
          if (reason !== 'selectOption') {
            setAutocompleteValue(null)
            setValue('company.kriyaCompanyIdentifier', '')
            setValue('company.companyRegistrationNumber', '')
            setValue('company.companyName', '')
            return
          }
          if ((value as SearchCompanyDetails) !== undefined) {
            const companyValue = value as SearchCompanyDetails
            setAutocompleteValue(companyValue)
            setValue('company.kriyaCompanyIdentifier', companyValue.kriyaCompanyIdentifier)
            setValue('company.companyRegistrationNumber', getGbCompanyRegistrationNumber(companyValue))
            setValue('company.companyName', companyValue.name)
            form.clearErrors('company')
          }
        }}
        options={data || []}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            placeholder="Search"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            inputProps={{
              ...params.inputProps,
              'data-hj-whitelist': true
            }}
          />
        )}
        getOptionLabel={formatOptionLabel}
        onInputChange={(_event, value, reason) => {
          if (reason === 'reset') {
            return
          }
          setValue('company.companyRegistrationNumber', '')
          setValue('company.companyName', '')
          setValue('company.kriyaCompanyIdentifier', '')
          setInputValue(value)
        }}
        renderOption={renderOption}
      />
      {errors?.company?.companyName && errors.company.companyName.type === 'required' && (
        <ErrorText id={'company-search'}>Please enter your full company name or registration number</ErrorText>
      )}
    </FormControl>
  )
}

const formatCompanyName = (company?: SearchCompanyDetails | null): string => {
  if (!company) return ''
  const identifier = getSuitableCompanyIdentifier(company?.auxiliaryIdentifiers)
  if (identifier) {
    return `${company.name} - ${identifier}`
  }
  return company.name
}

const formatCompanyAddress = ({ addressLine1, addressLine2, city, region, postCode }: Address): string => {
  const parts = [addressLine1, addressLine2, city, region, postCode]
  return parts.filter(Boolean).join(', ')
}

const formatOptionLabel = (option: string | SearchCompanyDetails) => {
  if (typeof option === 'string') return option
  if (option !== undefined) {
    return formatCompanyName(option)
  }
  return option
}

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SearchCompanyDetails) => {
  const name = formatCompanyName(option)
  const address = option.address ? formatCompanyAddress(option.address) : ''

  return (
    <Box component="li" {...props} key={option.kriyaCompanyIdentifier + option.name} data-cy={'company-picker-row'}>
      <Grid container sx={{ alignItems: 'flex-start' }} data-cy={'form-row'}>
        <Grid item md={12}>
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">{address}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
