export enum PaymentJourneyStatus {
  Started = 'Started',
  Expired = 'Expired',
  Ineligible = 'Ineligible',
  InsufficientFunds = 'InsufficientFunds',
  InsufficientFundsOther = 'InsufficientFundsOther',
  Completed = 'Completed',
  Eligible = 'Eligible',
  UnverifiedSoleTrader = 'UnverifiedSoleTrader',
  InReview = 'InReview',
  EligibilityCheckIsRequired = 'EligibilityCheckIsRequired',
  OnboardingIsRequired = 'OnboardingIsRequired',
  MfaSessionInProgress = 'MfaSessionInProgress',
  MfaSessionExpired = 'MfaSessionExpired',
  MfaSessionRejected = 'MfaSessionRejected',
  MfaNotSupported = 'MfaNotSupported',
  MfaSessionApproved = 'MfaSessionApproved',
  OnboardingChecksAreRequired = 'OnboardingChecksAreRequired',
  InsufficientFundsOnboardingChecksAreRequired = 'InsufficientFundsOnboardingChecksAreRequired',
  OnboardingChecksAreFailed = 'OnboardingChecksAreFailed'
}

export const TerminalPaymentJourneyStatuses = [
  PaymentJourneyStatus.Completed,
  PaymentJourneyStatus.Expired,
  PaymentJourneyStatus.Ineligible,
  PaymentJourneyStatus.InsufficientFunds,
  PaymentJourneyStatus.InsufficientFundsOther,
  PaymentJourneyStatus.InReview,
  PaymentJourneyStatus.UnverifiedSoleTrader,
  PaymentJourneyStatus.MfaSessionExpired,
  PaymentJourneyStatus.MfaSessionRejected,
  PaymentJourneyStatus.MfaNotSupported,
  PaymentJourneyStatus.InsufficientFundsOnboardingChecksAreRequired,
  PaymentJourneyStatus.OnboardingChecksAreFailed
]

export const MfaStatuses = [
  PaymentJourneyStatus.MfaNotSupported,
  PaymentJourneyStatus.MfaSessionInProgress,
  PaymentJourneyStatus.MfaSessionExpired,
  PaymentJourneyStatus.MfaSessionRejected,
  PaymentJourneyStatus.MfaSessionApproved
]
