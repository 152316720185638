import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const OrderNotFound = () => {
  useVirtualPageView(VirtualPage.ORDER_NOT_FOUND)

  return (
    <OrderErrors>
      <ErrorMessage>Something went wrong! Please head back to the page you were on.</ErrorMessage>
    </OrderErrors>
  )
}

export default OrderNotFound
