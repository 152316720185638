import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledStandardButton } from '../../components/common/button/Buttons'
import theme from '../../assets/theme'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { StyledErrorPanel } from '../../components/layout/Container'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const StyledButtonContainer = styled(Box)`
  margin-top: ${theme.spacing(11)};
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(10)};
  }
`

export const SemiBoldSpan = styled.span`
  font-weight: 600;
`

type OrderErrorProps = {
  errorHeader?: string | null
  marketplaceName?: string
  returnToMarketPlaceUrl?: string
  shouldHideReturnButton?: boolean
  children?: React.ReactNode
  trackAsError?: boolean
}

export const ErrorMessage = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Typography variant="body1" sx={{ marginTop: theme.spacing(7) }}>
      <Typography variant="errorBody">{children}</Typography>
    </Typography>
  )
}

const OrderErrors = (props: OrderErrorProps) => {
  const shouldTrack = props.trackAsError ?? false
  useVirtualPageView(VirtualPage.UNEXPECTED_ERROR, null, shouldTrack)

  const errorHeader = props.errorHeader || "We're sorry"

  const handleReturnClick = () => {
    window.location.replace(props.returnToMarketPlaceUrl as string)
  }

  return (
    <StyledErrorPanel>
      <Typography variant="errorHeading">{errorHeader}</Typography>
      {props.children}
      {props.marketplaceName && !props.shouldHideReturnButton && (
        <StyledButtonContainer>
          <StyledStandardButton variant="contained" onClick={handleReturnClick} id="return-to-marketplace-button">
            Return to {props.marketplaceName}
          </StyledStandardButton>
        </StyledButtonContainer>
      )}
    </StyledErrorPanel>
  )
}

export default OrderErrors
