import React, { useState } from 'react'
import Loading from '../../components/common/loading/Loading'
import { useRedirectPolling } from '../../custom-hooks/useRedirectPolling'
import OrderErrors, { ErrorMessage } from '../error/OrderErrors'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import { StyledStandardButton } from '../../components/common/button/Buttons'
import { useOrderInfo } from '../../custom-hooks/useOrderInfo'
import { Navigate } from 'react-router-dom'

const Polling = () => {
  const [isPolling, setIsPolling] = useState<boolean>(true)
  const { data, isLoading, error } = useOrderInfo()

  const polling = useRedirectPolling({
    onSuccess: (result) => {
      window.location.replace(result.redirectUrl)
    },
    onError: () => {
      setIsPolling(false)
    },
    timeout: 30
  })

  if (isLoading) return <Loading />
  if (error) return <Navigate to="/error" />
  if (!data) return <Navigate to="/not-found" />

  const marketPlaceName = data?.marketplace.name
  const header = `We're taking you back to ${marketPlaceName}. This may take a few seconds.`
  const content = `Shopify, ${marketPlaceName}’s ecommerce service provider, didn’t respond to us. Please try again.`
  const secondParagraph = `If you choose to return, we will redirect you back to ${marketPlaceName} and you may choose a different payment method to
        continue the checkout.`

  if (isPolling) {
    return <Loading header={header}></Loading>
  }

  const handleTryAgain = () => {
    setIsPolling(true)
    polling.rerun()
  }

  const handleReturn = () => {
    window.location.replace(data.paymentJourney.paymentCancelledRedirectUrl as string)
  }

  return (
    <OrderErrors trackAsError={true}>
      <ErrorMessage>{content}</ErrorMessage>
      <Typography variant="body1" sx={{ marginTop: theme.spacing(7) }}>
        {secondParagraph}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: {
            xs: 'center',
            mobile: 'flex-start'
          },
          flexDirection: {
            xs: 'column-reverse',
            mobile: 'row'
          },
          marginTop: {
            xs: theme.spacing(11)
          }
        }}
      >
        <StyledStandardButton
          variant="contained"
          onClick={handleTryAgain}
          id="try-again-button"
          sx={{
            borderWidth: 1
          }}
        >
          Try again
        </StyledStandardButton>
        <StyledStandardButton
          variant="outlined"
          onClick={handleReturn}
          id="return-to-marketplace-button"
          style={{
            marginLeft: theme.spacing(5)
          }}
        >
          Return to {marketPlaceName}
        </StyledStandardButton>
      </Box>
    </OrderErrors>
  )
}

export default Polling
