import React, { useEffect } from 'react'
import { Navigate, PathMatch } from 'react-router-dom'
import { PaymentJourneyStatus, TerminalPaymentJourneyStatuses } from '../types/enums/PaymentJourneyStatus'
import { PaymentJourney } from '../types/PaymentJourney'
import {
  GetActiveOrderStatusRedirection,
  GetTerminalOrderStatusRedirection
} from '../pages/error/OrderStatusRedirection'
import Loading from '../components/common/loading/Loading'
import { Marketplace } from '../types/Marketplace'

const Redirect = ({ url, marketplace }: { url: string; marketplace?: Marketplace | null }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return marketplace ? (
    <Loading
      header={`The order has been approved`}
      bodyMessage={`We're redirecting you to ${marketplace.name}`}
      addendumMessage={`Please don't close the window.`}
    ></Loading>
  ) : null
}

const PageGuard = (
  currentPage: PathMatch<'shortCode'> | null,
  paymentJourney: PaymentJourney,
  marketplace: Marketplace
) => {
  let redirectTo = undefined

  const status = paymentJourney.status as PaymentJourneyStatus

  if (
    status === PaymentJourneyStatus.EligibilityCheckIsRequired ||
    status === PaymentJourneyStatus.OnboardingIsRequired ||
    status === PaymentJourneyStatus.OnboardingChecksAreRequired
  ) {
    return <Redirect url={paymentJourney.onboardingJourneyUrl!} />
  }

  if (status === PaymentJourneyStatus.Completed && paymentJourney.paymentAcceptedRedirectUrl) {
    return <Redirect url={paymentJourney.paymentAcceptedRedirectUrl} marketplace={marketplace} />
  }

  if (TerminalPaymentJourneyStatuses.includes(status)) {
    redirectTo = GetTerminalOrderStatusRedirection(status)
  } else {
    redirectTo = GetActiveOrderStatusRedirection(status)
  }

  if (currentPage && redirectTo !== undefined) {
    if (currentPage.pathname.indexOf(redirectTo) < 0) {
      const redirectToPath = currentPage.pathnameBase + '/' + redirectTo
      return <Navigate to={redirectToPath} replace />
    }
  }

  return null
}

export default PageGuard
